import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(e) {
    var evt = event || window.event;
    var form = $('#addItemForm');
    $('#variant_sku').val(e.value);

    window.Rails.fire(form[0], 'submit')

    var scanObj = window.scan.cameraScanner();
    scanObj.restart();
  }
}
