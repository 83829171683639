import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Listen for Turbo Frame events
    window.addEventListener("turbo:before-stream-render", this.closeModal);
  }

  disconnect() {
    // Clean up event listeners
    window.removeEventListener("turbo:before-stream-render", this.closeModal);
  }

  closeModal(event) {
    // Handle turbo:before-stream-render event
    // This event occurs before the Turbo Stream is rendered
    // You can perform actions before the replacement occurs

    // Access the target element where the event originated
    const targetElement = event.target;

    const framevalue = targetElement.getAttribute("target")
    if (framevalue == 'addresses_list') {
      // If the target element is within a turbo-frame
      // Get the ID of the parent frame
      try {
        // Remove the modal backdrop and reset the modal state
        const modals = document.querySelectorAll('.address-form-modal');
        modals.forEach((modal) => {
          window.modalHideAndDispose(modal)
          // Clean up any other modal-related elements as needed
        });
      } catch(err) {
        console.log("ERROR: "+err)
      }
    }
  }
}
